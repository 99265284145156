import {
  FETCH_DOCUMENT_DATA,
  FETCH_DOCUMENT_STATS,
  DOCUMENT_STATS_LOADING,
  DOCUMENT_STATS_SUCCESS,
  DOCUMENT_STATS_ERROR,
  DOCUMENT_LOADING,
  DOCUMENT_ERROR,
  DOCUMENT_SUCCESS,
  REMOVE_LABEL,
  ADD_DOCUMENTS_TO_LABEL,
  REMOVE_DOCUMENTS_FROM_LABEL,
  FETCH_APPLICATION_INFO,
  APPLICATION_INFO_SUCCESS,
  APPLICATION_INFO_ERROR,
  APPLICATION_INFO_LOADING,
  APPLICATION_QUEUE_ERROR,
  APPLICATION_QUEUE_SUCCESS,
  FETCH_APPLICATION_QUEUE,
  APPLICATION_QUEUE_LOADING,
  EDIT_APPLICATION,
  REQUEST_LEASE,
  LEASE_LOADING,
  LEASE_SUCCESS,
  LEASE_ERROR,
  FETCH_LABELS_LIST,
  ADD_LABEL,
  EDIT_LABEL,
  LABELS_LIST_SUCCESS,
  LABELS_LIST_ERROR,
  LABELS_LIST_LOADING,
  FETCH_LABEL,
  LABEL_LOADING,
  LABEL_ERROR,
  LABEL_SUCCESS
} from "../sagas/types";

import {
  put,
  call,
  select,
  all,
  takeEvery,
  takeLatest
} from "redux-saga/effects";
import * as TekaApi from "api/teka";
import * as FolksApi from "api/folks";
import { sagaCapi } from "capi/appclient";

import bootstrap from "bootstrap";

import * as Teka from "../capi/teka";
import { translate } from "utils/language";
import { emptyObject } from "../utils/constants";
import { StorageGet } from "../capi/folks";

const DOCUMENT_APPLICATIONS_PER_PAGE = 6; // liczba wniosków na stronie dokumentu
const LABELS_STORAGE_KEY = "teka_labels"; // klucz, pod jakim zapisane są etykiety w storage

export default function* tekaWatcher() {
  yield all([
    takeLatest(FETCH_DOCUMENT_DATA, fetchDocumentData),
    takeLatest(FETCH_DOCUMENT_STATS, fetchDocumentStats),
    takeLatest(FETCH_LABELS_LIST, fetchLabels),
    takeEvery(ADD_LABEL, addLabel),
    takeEvery(EDIT_LABEL, editLabel),
    takeEvery(REMOVE_LABEL, removeLabel),
    takeLatest(FETCH_LABEL, fetchLabel),
    takeEvery(ADD_DOCUMENTS_TO_LABEL, addDocumentsToLabel),
    takeEvery(REMOVE_DOCUMENTS_FROM_LABEL, removeDocumentsFromLabel),
    takeLatest(FETCH_APPLICATION_INFO, fetchApplicationInfo),
    takeLatest(EDIT_APPLICATION, editApplication),
    takeLatest(FETCH_APPLICATION_QUEUE, fetchApplicationQueue),
    takeLatest(REQUEST_LEASE, leaseRequest),
  ]);
}



/**
 * Pobranie statystyk dla dokumentu.
 */
function* fetchDocumentStats({ payload }) {
  let { document_id, leases_from, leases_to, onSuccess, onError } = payload;

  yield put({
    type: DOCUMENT_STATS_LOADING
  });

  try {
    const result = yield call(TekaApi.fetchDocumentStats, document_id, leases_from, leases_to);

    if (result.status === 200) {
      const stats = result.data;

      yield put({
        type: DOCUMENT_STATS_SUCCESS,
        payload: stats
      });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      yield put({
        type: DOCUMENT_STATS_ERROR,
        payload: result.status
      });
      if (onError) {
        onError(result.status);
      }
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: DOCUMENT_STATS_ERROR,
      payload: 500
    });
    if (onError) {
      onError(500);
    }
  }
}

/**
 * Pobranie danych dokumentu razem z powiązaniami i wnioskami o dostęp do danego dokumentu (jeśli zalogowany użytkownik ma prawa do ich obsługi)
 */
function* fetchDocumentData({ payload }) {
  let {
    document_id,
    data = ["all"],
    loading = true,
    applicationsOffset = 0
  } = payload;
  /**
   * PAYLOAD
   * document_id - identyfikator dokumentu (jeśli nie jest podany, to zostanie pobrany id dokumentu zapisanego pod state.teka.document)
   * loading == true || false
   * data - tablica z informacją, które "części" dokumentu pobierać; "all" - wszystkie
   */

  if (loading) {
    yield put({
      type: DOCUMENT_LOADING
    });
  }
  else {
    yield put({
      type: "DOCUMENT_RELOADING"
    });
  }

  const documentFromState = yield select(state => state.teka.document);

  if (!document_id) {
    // Jesli nie został podany id dokumentu zakładamy, że chodzi o dokument obecnie zapisany pod state.teka.document
    document_id = documentFromState.data.document_id;
  }

  let documentData =
    data.indexOf("all") > -1 ? {} : { ...documentFromState.data };

  try {
    // Pobieranie głównych danych
    if (data.indexOf("all") > -1 || data.indexOf("main") > -1) {
      const [{ result }, { result: paramResult }] = yield sagaCapi(
        "teka",
        new Teka.DocumentInfo(document_id),
        new Teka.ParamsGet([
          "lease.by_kind.reservation.duration",
          "lease.by_kind.loan.duration",
          "lease.by_pool.physical.enabled",
          "viewer.render.default.dpi"
        ])
      );

      if (result.status !== 200) {
        yield put({
          type: DOCUMENT_ERROR,
          payload: result.message || translate("pages.list.teka.document.error.fetch")
        });
        return;
      }

      /* 
        Przy ponownym pobraniu tego samego dokumentu reducer łączy obiekt ze store'a z nowo pobranym (po wysłaniu akcji DOCUMENT_SUCCESS),
        jest to tak zbudowane po to, żeby można było na nowo pobrać tylko część dokumentu 
        (np. gdy edytujemy dowiązanie dokumentu, nie trzeba na nowo pobierać wniosków do dokumentu, pobieramy tylko dowiązania).
        W przypadku, gdy dokument miał licencję, a w ramach edycji ją usuniemy, przy ponownym pobraniu serwer zwróci obiekt bez klucza "license",
        a więc przy łęczeniu starego obiektu z nowym stara licencja nie zostanie podmieniona. 
        Dlatego na wstępie 'zakładamy', że serwer zwraca "undefined" pod kluczem "license" (to samo dla innych kluczy)
       */
      const newData = {
        license: undefined,
        name: undefined,
        sowa_id: undefined,
        catalogue_id: undefined,
        state: "hidden",
        ...result.data
      }

      documentData = {
        ...documentData,
        ...newData
      };

      if (paramResult.status === 200) documentData.params = paramResult.data;
      else documentData.params = {};

      const users = new Set(); // id wszystkich użytkowników, którzy 'występują' w danych dokumentu - właściciel, odbiorcy uprawnień itp - zostały pobrane tylko ich id
      const handleUserInfo = obj => {
        // wyciąganie id użytkownika z danego obiektu, jeśli tam występuje
        if (obj && obj.user_id) {
          users.add(obj.user_id);
        }
      };

      if (documentData.custody) {
        documentData.custody.forEach(item => {
          // wydobcie informacji o odbiorcach uprawnień
          handleUserInfo(item.from);
          handleUserInfo(item.to);
        });
      }
      handleUserInfo(documentData.owner); // Wydobycie informacji o właścicielu dokumentu

      if (users.size > 0) {
        const usersResponse = yield call(FolksApi.fetchUsers, users); // pobieranie informacji o zebranych użytkownikach
        const usersLabels = {};
        usersResponse.forEach(res => {
          // jeśli pobranie informacji się powiodło, zapisujemy label użytkownika w obiekcie usersLabels, gdzie kluczem będzie jego id
          if (res.status === 200) {
            usersLabels[res.exec[1].user_id] = res.result.data.label;
          }
        });

        const addLabel = obj => {
          // funkcja dodająca klucz label z etykietą użytkownika w każdym obiekcie, w którym znajduje się id użytkownika
          if (obj && usersLabels.hasOwnProperty(obj.user_id)) {
            obj.label = usersLabels[obj.user_id];
          }
        };

        if (documentData.custody) {
          documentData.custody.forEach(item => {
            // dodanie labeli odbiorców uprawnień
            addLabel(item.from);
            addLabel(item.to);
          });
        }
        addLabel(documentData.owner); // dodanie etykiety właściciela dokumentu
      }
    } // - pobieranie głównych danych

    // Pobieranie wniosków o dostęp do dokumentu (jeśli zalogowany użytkownik ma do tego prawo)
    if (data.indexOf("all") > -1 || data.indexOf("applications") > -1) {
      if (
        documentData.my_custody === "staff" ||
        documentData.my_custody === "owner" ||
        documentData.my_custody === "manage"
      ) {
        const applicationsResult = yield call(
          TekaApi.applicationQueue,
          "access",
          DOCUMENT_APPLICATIONS_PER_PAGE,
          applicationsOffset,
          document_id
        );
        if (applicationsResult.status === 200) {
          yield call(getApplicationsUsersInfo, applicationsResult.data.items); // pobranie etykiet wnioskodawców
          documentData.applications = {
            error: null,
            data: applicationsResult.data
          };
        } else {
          documentData.applications = {
            error: applicationsResult.status,
            data: {}
          };
        }
      }
    }

    // Pobieranie wniosków dotyczących dokumentu złożonych przez zalogowanego użytkownika
    if (data.indexOf("all") > -1 || data.indexOf("my_applications") > -1) {
      const isUserLoggedIn = yield select(
        state => state.session.isUserLoggedIn
      );
      if (isUserLoggedIn) {
        const my_applications = {};
        const accessApplicationsResult = yield call(
          TekaApi.applicationList,
          "access",
          100,
          0,
          document_id
        );
        if (accessApplicationsResult.status === 200) {
          my_applications.access = accessApplicationsResult.data;
        }
        const catalogueApplicationsResult = yield call(
          TekaApi.applicationList,
          "catalogue",
          100,
          0,
          document_id
        );
        if (catalogueApplicationsResult.status === 200) {
          my_applications.catalogue = catalogueApplicationsResult.data;
        }

        documentData.my_applications = my_applications;
      }
    }

    yield put({
      type: DOCUMENT_SUCCESS,
      payload: documentData
    });
  } catch (error) {
    console.error(error);

    yield put({
      type: DOCUMENT_ERROR,
      payload: translate("error.failedToConnect")
    });
  }
}



/**
 * Pobranie listy etykiet
 */
function* fetchLabels({ payload }) {
  const { loading = true } = payload || emptyObject;

  if (loading) {
    yield put({
      type: LABELS_LIST_LOADING,
      payload: true
    });
  }

  try {
    const result = yield call(FolksApi.getStorageKey, LABELS_STORAGE_KEY);
    if (result.status === 200) {
      yield put({
        type: LABELS_LIST_SUCCESS,
        payload: result.data ? JSON.parse(result.data) : []
      });
    } else {
      yield put({
        type: LABELS_LIST_ERROR,
        payloa: result.status
      });
    }
  } catch (error) {
    yield put({
      type: LABELS_LIST_ERROR,
      payloa: 500
    });
  }
}

/**
 * Dodawanie nowej etykiety
 */
function* addLabel({ payload }) {
  const { name, onSuccess, onError } = payload;

  try {
    const fetchResult = yield call(FolksApi.getStorageKey, LABELS_STORAGE_KEY);
    // Pobranie etykiet z serwera
    // nie pobieramy z lokalnego stanu, żeby na pewno wartość w storage była zawsze aktualna
    if (fetchResult.status !== 200) {
      if (onError) {
        onError(fetchResult.status);
      }
      return;
    }

    const labels = fetchResult.data ? JSON.parse(fetchResult.data) : [];
    if (
      labels.filter(
        label => label.name.trim().toLowerCase() === name.trim().toLowerCase()
      ).length > 0
    ) {
      // Jest już etykieta o takiej nazwie
      if (onError) {
        onError(409);
      }
      return;
    }

    const newId = labels.length + 1;
    const newLabels = [
      ...labels,
      {
        label_id: newId,
        name: name,
        documents: []
      }
    ];

    const saveResult = yield call(
      FolksApi.saveStorageKey,
      LABELS_STORAGE_KEY,
      JSON.stringify(newLabels)
    );
    if (saveResult.status === 204) {
      yield put({
        type: LABELS_LIST_SUCCESS,
        payload: newLabels
      });
      if (onSuccess) {
        onSuccess(newId);
      }
    } else {
      if (onError) {
        onError(saveResult.status);
      }
    }
  } catch (error) {
    if (onError) {
      onError(500);
    }
  }
}

/**
 * Edycja nazwy etykiety
 */
function* editLabel({ payload }) {
  const { label_id, name, onSuccess, onError } = payload;

  try {
    const fetchResult = yield call(FolksApi.getStorageKey, LABELS_STORAGE_KEY);
    // Pobranie etykiet z serwera
    // nie pobieramy z lokalnego stanu, żeby na pewno wartość w storage była zawsze aktualna
    if (fetchResult.status !== 200) {
      if (onError) {
        onError(fetchResult.status);
      }
      return;
    }

    const labels = fetchResult.data ? JSON.parse(fetchResult.data) : [];

    const labelIndex = labels.findIndex(label => label.label_id === label_id);
    if (labelIndex === -1) {
      if (onError) {
        onError(404);
      }
      return;
    }

    labels[labelIndex].name = name; // Przypisanie nowej nazwy
    const saveResult = yield call(
      FolksApi.saveStorageKey,
      LABELS_STORAGE_KEY,
      JSON.stringify(labels)
    );
    if (saveResult.status === 204) {
      yield put({
        type: LABELS_LIST_SUCCESS,
        payload: labels
      });

      const openLabel = yield select(state => state.teka.labels.single.data);
      if (openLabel.label_id && openLabel.label_id === label_id) {
        // Edytowana etykieta jest wczytana do state.teka.label.single - zmieniamy jej nazwę w storze
        const newLabel = { ...openLabel, name };
        yield put({
          type: LABEL_SUCCESS,
          payload: newLabel
        });
      }

      if (onSuccess) {
        onSuccess();
      }
    } else {
      if (onError) {
        onError(saveResult.status);
      }
    }
  } catch (error) {
    console.log(error);
    if (onError) {
      onError(500);
    }
  }
}

function* removeLabel({ payload }) {
  // eslint-disable-next-line
  const { label_id, onSuccess, onError } = payload;

  try {
    const fetchResult = yield call(FolksApi.getStorageKey, LABELS_STORAGE_KEY);
    // Pobranie etykiet z serwera
    // nie pobieramy z lokalnego stanu, żeby na pewno wartość w storage była zawsze aktualna
    if (fetchResult.status !== 200) {
      if (onError) {
        onError(fetchResult.status);
      }
      return;
    }

    const labels = fetchResult.data ? JSON.parse(fetchResult.data) : [];
    const labelIndex = labels.findIndex(
      label => label.label_id === Number(label_id)
    ); // Indeks etykiety od usunięcia
    if (labelIndex !== -1) {
      labels.splice(labelIndex, 1); // Usunięcie etykiety z tablicy
    }

    const saveResult = yield call(
      FolksApi.saveStorageKey,
      LABELS_STORAGE_KEY,
      JSON.stringify(labels)
    );
    if (saveResult.status === 204) {
      yield put({
        type: LABELS_LIST_SUCCESS,
        payload: labels
      });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      if (onError) {
        onError(saveResult.status);
      }
    }
  } catch (error) {
    if (onError) {
      onError(500);
    }
  }
}

function* addDocumentsToLabel({ payload }) {
  // eslint-disable-next-line
  const {
    documents, // tablica z id dokumentów
    label_id, // id etykiety
    onSuccess,
    onError
  } = payload;

  try {
    const fetchResult = yield call(FolksApi.getStorageKey, LABELS_STORAGE_KEY);
    // Pobranie etykiet z serwera
    // nie pobieramy z lokalnego stanu, żeby na pewno wartość w storage była zawsze aktualna
    if (fetchResult.status !== 200) {
      if (onError) {
        onError(fetchResult.status);
      }
      return;
    }

    const labels = fetchResult.data ? JSON.parse(fetchResult.data) : [];

    const labelIndex = labels.findIndex(label => label.label_id === label_id);
    if (labelIndex === -1) {
      if (onError) {
        onError(404);
      }
      return;
    }

    const docsArray = [...labels[labelIndex].documents, ...documents]; // Tablica dokumentów złożona z dotychczasowych dokumentów podpiętych pod etykietę i tych nowych
    const docsWithoutDuplicates = [...new Set(docsArray)]; //  Usunięcie ewentualnych duplikatów

    labels[labelIndex].documents = docsWithoutDuplicates;

    const saveResult = yield call(
      FolksApi.saveStorageKey,
      LABELS_STORAGE_KEY,
      JSON.stringify(labels)
    );
    if (saveResult.status === 204) {
      yield put({
        type: LABELS_LIST_SUCCESS,
        payload: labels
      });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      if (onError) {
        onError(saveResult.status);
      }
    }
  } catch (error) {
    console.log(error);
    if (onError) {
      onError(500);
    }
  }
}

function* removeDocumentsFromLabel({ payload }) {
  // eslint-disable-next-line
  const { onSuccess, onError, label_id, documents } = payload;

  try {
    const fetchResult = yield call(FolksApi.getStorageKey, LABELS_STORAGE_KEY);
    // Pobranie etykiet z serwera
    // nie pobieramy z lokalnego stanu, żeby na pewno wartość w storage była zawsze aktualna
    if (fetchResult.status !== 200) {
      if (onError) {
        onError(fetchResult.status);
      }
      return;
    }

    const labels = fetchResult.data ? JSON.parse(fetchResult.data) : [];

    const labelIndex = labels.findIndex(label => label.label_id === label_id);
    if (labelIndex === -1) {
      if (onError) {
        onError(404);
      }
      return;
    }

    labels[labelIndex].documents = labels[labelIndex].documents.filter(
      doc => !documents.includes(doc)
    ); // Usunięcie dokumentów podanych w documents

    const saveResult = yield call(
      FolksApi.saveStorageKey,
      LABELS_STORAGE_KEY,
      JSON.stringify(labels)
    );
    if (saveResult.status === 204) {
      yield put({
        type: LABELS_LIST_SUCCESS,
        payload: labels
      });
      if (onSuccess) {
        onSuccess();
      }
    } else {
      if (onError) {
        onError(saveResult.status);
      }
    }
  } catch (error) {
    console.log(error);
    if (onError) {
      onError(500);
    }
  }
}

/**
 *  Pobranie dokumentów zapisanych w etykiecie
 */
function* fetchLabel({ payload }) {
  const { label_id } = payload;

  yield put({
    type: LABEL_LOADING,
    payload: true
  });

  try {
    const fetchResult = yield call(FolksApi.getStorageKey, LABELS_STORAGE_KEY);
    // Pobranie etykiet z serwera
    // nie pobieramy z lokalnego stanu, żeby na pewno wartość w storage była zawsze aktualna
    if (fetchResult.status !== 200) {
      yield put({
        type: LABEL_ERROR,
        payload: fetchResult.status
      });
      return;
    }

    const labels = fetchResult.data ? JSON.parse(fetchResult.data) : [];
    const labelIndex = labels.findIndex(
      label => label.label_id === Number(label_id)
    );
    if (labelIndex === -1) {
      yield put({
        type: LABEL_ERROR,
        payload: 404
      });
      return;
    }

    const labelInfo = { ...labels[labelIndex] };

    if (labelInfo.documents.length > 0) {
      const response = yield call(
        TekaApi.fetchDocumentsList,
        labelInfo.documents
      );
      const documents = response
        .filter(exec => exec.result.status === 200)
        .map(exec => {
          const { data } = exec.result;
          const documentData = {};
          // Wczytujemy te same dane, które są zwracane przez Teka.TreeList, bo dokumenty będą wyświetlane przez ten sam komponent co Explorer
          documentData.tree_id = data.document_id;
          documentData.kind = "document";
          documentData.name = data.name;
          documentData.document = {
            file: data.file,
            document_id: data.document_id
          };

          if (data.attachments) {
            // Szukanie URL okładki
            const frontAttachmentIndex = data.attachments.findIndex(
              item => item.kind === "front"
            );
            if (frontAttachmentIndex !== -1) {
              documentData.document.front_url =
                data.attachments[frontAttachmentIndex].url;
            }
          }

          return documentData;
        });

      labelInfo.documents = documents;
    }

    yield put({
      type: LABEL_SUCCESS,
      payload: labelInfo
    });
  } catch (error) {
    console.log(error);
    yield put({
      type: LABEL_ERROR,
      payload: 500
    });
  }
}

function* editApplication({ payload }) {
  const {
    application_id,
    message,
    verdict,
    claim,
    state,
    onSuccess,
    onError,
    fetch = true
  } = payload;

  try {
    const result = yield call(
      TekaApi.applicationEdit,
      application_id,
      message,
      verdict,
      claim,
      state
    );

    if (result.status === 204) {
      if (onSuccess) {
        onSuccess();
      }
      if (fetch) {
        yield put({
          type: FETCH_APPLICATION_INFO,
          payload: {
            application_id,
            loading: false
          }
        });
      }
    } else {
      if (onError) {
        onError(result.status, result.message);
      }
    }
  } catch (error) {
    if (onError) {
      onError(500);
    }
  }
}

function* fetchApplicationInfo({ payload }) {
  const { application_id, onSuccess, onError, loading = true } = payload;

  if (loading) {
    yield put({
      type: APPLICATION_INFO_LOADING
    });
  }

  try {
    const result = yield call(TekaApi.applicationInfo, application_id);

    if (result.status === 200) {
      // Pobranie informacji o dokumencie, którego dotyczy wniosek
      if (
        ["access", "catalogue"].indexOf(result.data.kind) > -1 &&
        result.data.document &&
        result.data.document.document_id
      ) {
        const documentResult = yield call(
          TekaApi.fetchDocumentData,
          result.data.document.document_id
        );
        if (documentResult.status === 200) {
          result.data.document = documentResult.data;
        }
      }

      yield call(getApplicationsUsersInfo, [result.data]);
      // pobranie informacji o użytkownikach pojawiających się w danych wniosku

      if (onSuccess) onSuccess(result.data);

      yield put({
        type: APPLICATION_INFO_SUCCESS,
        payload: result.data
      });
    } else {
      if (onError) onError(result.status);
      yield put({
        type: APPLICATION_INFO_ERROR,
        payload: result.status
      });
    }
  } catch (error) {
    if (onError) onError(500);
    yield put({
      type: APPLICATION_INFO_ERROR,
      payload: 500
    });
    console.log(error);
  }
}

function* fetchApplicationQueue({ payload }) {
  const { kind, volume, offset, document_id, onSuccess, onError } = payload;
  yield put({
    type: APPLICATION_QUEUE_LOADING,
    payload: { kind }
  });
  try {
    const result = yield call(
      TekaApi.applicationQueue,
      kind,
      volume,
      offset,
      document_id
    );

    if (result.status === 200) {
      yield call(getApplicationsUsersInfo, result.data.items);
      // pobranie etykiet pojawiających się użytkowników i dopisanie ich do danych aplikacji

      if (onSuccess) onSuccess(result.data);
      yield put({
        type: APPLICATION_QUEUE_SUCCESS,
        payload: {
          kind,
          data: result.data
        }
      });
    } else {
      if (onError) onError(result.status);
      yield put({
        type: APPLICATION_QUEUE_ERROR,
        payload: {
          kind,
          error: result.status
        }
      });
    }
  } catch (error) {
    if (onError) onError(500);
    yield put({
      type: APPLICATION_QUEUE_ERROR,
      payload: {
        kind,
        error: 500
      }
    });
  }
}

/**
 * Dla każdej pobranej aplikacji wyszukuje występujących w nich użytkowników (claimed_by lub applicant),
 * pobiera ich labele a następnie przypisuje pod kluczem label do obiektu claimed_by lub applicant
 */
function* getApplicationsUsersInfo(applications) {
  const users = new Set();
  applications.forEach(app => {
    // dodawanie do Seta users wszystkich użytkowników pojawiających się w danych aplikacji
    if (app.applicant && app.applicant.user_id) {
      users.add(app.applicant.user_id);
    }
    if (app.claimed_by && app.claimed_by.user_id) {
      users.add(app.claimed_by.user_id);
    }
  });

  const usersResult = yield call(FolksApi.fetchUsers, users);
  const usersObj = {};
  usersResult.forEach(item => {
    // tworzenie obiektu; klucz: user_id, wartość: label
    const user_id = item.result.data.user_id;
    const label = item.result.data.label;
    usersObj[user_id] = label;
  });

  applications.forEach(app => {
    // dodawanie klucza label z etykietą użytkownika do obiektów, w których znajduje się klucz user_id
    if (app.applicant && app.applicant.user_id) {
      if (usersObj.hasOwnProperty(app.applicant.user_id)) {
        app.applicant.tranId = usersObj[app.applicant.user_id];
      }
    }
    if (app.claimed_by && app.claimed_by.user_id) {
      if (usersObj.hasOwnProperty(app.claimed_by.user_id)) {
        app.claimed_by.tranId = usersObj[app.claimed_by.user_id];
      }
    }
  });
}

/**
 * Prośba o uzyskanie dostępu do dokumentu online
 * @see https://dav.sokrates.pl/strix/leases.html#_leaserequest
 */
function* leaseRequest({ payload }) {
  const {
    document_id,
    password,
    duration,
    request,
    loading = true, // false zapobiega rerenderowaniu
    setError = true, // Czy modyfikować teka.lease.error w reducerze
    onError,
    onSuccess
  } = payload;

  try {
    if(loading)
      yield put({
        type: LEASE_LOADING,
        payload: true
      });
    
    // FIXME: jak to portniemy na nowszy kod to nie trzeba obu wywoływać, tylko jedno albo drugie
    const [{ result }, { result: infoResult }] = yield sagaCapi("teka", new Teka.LeaseRequest(document_id, {
      password,
      duration,
      request
    }), new Teka.LeaseInfo(document_id, { countWaiting: true }));
    
    if (result.status === 200) {
      let pos = null;
      const [{ result: storageResult }] = yield sagaCapi("folks", new StorageGet(`docPos:${document_id}`));
      if (storageResult.status === 200) {
        const [y, x, z] = (storageResult.data || "").split(",")
        const scrollTop = +y, scrollLeft = +x, zoomIndex = +z;
        if (!isNaN(scrollTop) && !isNaN(scrollLeft) && !isNaN(zoomIndex))
          pos = { scrollTop, scrollLeft, zoomIndex };
      }
      
      const info = infoResult.data || emptyObject;
      const data = { ...result.data, waiting_ahead: info.waiting_ahead, waiting_behind: info.waiting_behind, _position: pos }
      yield put({
        type: LEASE_SUCCESS,
        payload: data
      });
      if (onSuccess) {
        onSuccess(data);
      }
    } else {
      if (setError) {
        yield put({
          type: LEASE_ERROR,
          payload: result
        });
      }
      if (onError) {
        onError();
      }
    }
  } catch (error) {
    console.log(error);
    if (setError) {
      yield put({
        type: LEASE_ERROR,
        payload: { status: 500 }
      });
    }
    if (onError) {
      onError();
    }
  }
}
