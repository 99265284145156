import React, { useContext, useEffect, useRef } from "react";

import { Button } from ".";
import { BetterContextProvider } from "../../utils/context";
import { doNothing, emptyArray, emptyObject, emptySet } from "../../utils/constants";
import { scheduleReactUpdate, useBind, useDerivedState, useSignal } from "../../utils/hooks";

// TODO: integracja z formularzami, może w postaci <SubmitAction/> i <ResetAction/>

type OnClick = React.MouseEventHandler<HTMLElement>

type CommonActionProps = {
  variant?: "contained" | "icon"
  preset?: string // FIXME
  label?: React.ReactNode
  icon?: string
  onClick?: OnClick
  disabled?: boolean
  loading?: boolean
  level?: string
}

export type ActionSlotProps = {
  name: string
} & CommonActionProps

export type ActionProps = {
  slot: string
  order?: number
} & CommonActionProps

type ActionPlugProps = {
  order?: number
} & CommonActionProps

let slotId = 1;

export const ActionSlot = React.memo((props: ActionSlotProps) => {
  const ctx = useActionContext(props.level);
  const ref = useRef<Slot | null>(null);
  const wakeUp = useSignal();
  
  if (ref.current === null) {
    const slot: Slot = {
      id: `actionSlot-${slotId++}`,
      wakeUp,
    }
    
    ref.current = slot;
  }
  
  const slot = ref.current!;
  
  useEffect(() => {
    return ctx.addSlot(props.name, slot);
  }, [ctx, props.name]);
  
  // podłączamy pod siebie pierwszą zarejestrowaną akcję dla naszego slotu
  let plug: Partial<ActionPlugProps> = emptyObject;
  for (const p of (ctx.plugs.get(props.name)! || emptyArray)) {
    plug = p.props;
    break;
  }
  
  const onClick = plug.onClick || props.onClick;
  const disabled = onClick ? (plug.disabled || props.disabled) : true; 

  return <Button
    variant={plug.variant || props.variant}
    preset={plug.preset || props.preset}
    icon={plug.icon || props.icon}
    children={plug.label || (props.preset ? undefined : props.label)}
    loading={plug.loading || props.loading}
    onClick={onClick}
    disabled={disabled} />
});

export const ActionMultislot = React.memo((props: ActionSlotProps) => {
  type PlugPriv = Plug & {
    order: number;
  }
  
  const ctx = useActionContext(props.level);
  const ref = useRef<Slot | null>(null);
  const wakeUp = useSignal();
  
  if (ref.current === null) {
    const slot: Slot = {
      id: `actionSlot-${slotId++}`,
      wakeUp,
    }
    
    ref.current = slot;
  }
  
  const slot = ref.current!;
  
  useEffect(() => {
    return ctx.addSlot(props.name, slot);
  }, [ctx, props.name]);
  
  // jako że używamy React.memo, a ctx nie będzie nas budził praktycznie nigdy,
  // to nie cache'ujemy dodatkowo tej logiki renderowania
  const plugs = [...(ctx.plugs.get(props.name)! || emptyArray)] as PlugPriv[];
  
  for (let i = 0; i < plugs.length; i++) {
    let plug: PlugPriv = plugs[i];
    plugs[i] = plug = { ...plug, order: plug.props.order ?? (i + 1) };
  }
  
  plugs.sort(plugSort);
  
  const slotProps = props;
  return <>{plugs.map(({ props }) => (
    <Button
      variant={props.variant || slotProps.variant}
      preset={props.preset || slotProps.preset}
      icon={props.icon || slotProps.icon}
      children={props.label || (props.preset ? undefined : slotProps.label)}
      loading={props.loading || slotProps.loading}
      onClick={props.onClick || slotProps.onClick}
      disabled={props.disabled ?? slotProps.disabled ?? !(props.onClick || slotProps.onClick)} />
  ))}</>;
});

const plugSort = (a: { order: number }, b: { order: number }) => a.order - b.order;

export const Action = React.memo((props: ActionProps) => {
  useAction(props);
  
  return null;
});

// TODO: opt - odpalamy tutaj kupę hooków dla jednej akcji, fajnie by mieć możliwość hurtowego definiowania

export function useAction(props: ActionProps): void {
  const ctx = useActionContext(props.level);
  const ref = useRef<Plug | null>(null);
  const boundOnClick = useBind(props.onClick || doNothing);
  const onClick = props.onClick ? boundOnClick : undefined;
  
  const actionProps = useDerivedState(
    makeActionProps,
    props.variant,
    props.preset,
    props.label,
    props.icon,
    props.disabled,
    props.loading,
    onClick
  );
  
  if (ref.current === null) {
    const plug: Plug = {
      props: actionProps
    }
    
    ref.current = plug;
  }
  
  const plug = ref.current!;
  
  // aktualizujemy przynależność do kontekstu po zmianie `level`, a więc i `ctx`, lub nazwy slotu
  useEffect(() => ctx.addPlug(props.slot, plug), [ctx, props.slot]);
  
  // aktualizujemy nasze propsy przekazane do slotu po ich zmianie
  useEffect(() => {
    if (plug.props !== actionProps) {
      plug.props = actionProps;
      ctx.wakeSlots(props.slot);
    }
  }, [props.variant, props.preset, props.label, props.icon, onClick, props.disabled, props.loading]);
}

function makeActionProps(
  variant: CommonActionProps["variant"],
  preset: CommonActionProps["preset"],
  label: CommonActionProps["label"],
  icon: CommonActionProps["icon"],
  disabled: CommonActionProps["disabled"],
  loading: CommonActionProps["loading"],
  onClick: CommonActionProps["onClick"],
): ActionPlugProps {
  return { variant, preset, label, icon, disabled, loading, onClick };
}

export function ActionService({ id, levels, children }: { id: string, levels: string | Iterable<string>, children: React.ReactNode }) {
  const parent = useContext(actionContext);
  const ctx = useDerivedState(ActionContext.make, parent, id, levels);
  return <BetterContextProvider context={actionContext} value={ctx} children={children} />;
}

function useActionContext(level?: string) {
  let ctx = useContext(actionContext);
  if (level) {
    while (!ctx.levels.has(level) && ctx.parent) {
      ctx = ctx.parent;
    }
  }
  return ctx;
}

class ActionContext {
  id: string
  levels: Set<string>
  parent: ActionContext | null
  slots: Map<string, Set<Slot>>
  plugs: Map<string, Set<Plug>>
  
  constructor(parent: ActionContext | null, id: string, levels: Iterable<string>) {
    this.parent = parent;
    this.id = id;
    this.levels = new Set(levels);
    this.slots = new Map();
    this.plugs = new Map();
  }
  
  addSlot(name: string, slot: Slot) {
    const { slots, plugs } = this;
    slots.set(name, (slots.get(name) || new Set()));
    const set = slots.get(name)!;
    set.add(slot);
    // for (const plug of (plugs.get(name)! || emptySet)) {
    //   slot.plugs.add(plug);
    // }
    return () => {
      // TODO: zastanowić się czy nie chcemy odwlec usunięcia by zachować kolejność podczas przemontowania
      //       ale to niszowe zagadnienie, bo zwykle nie powinno być wielu slotów z tą samą nazwą
      set.delete(slot);
    }
  }
  
  addPlug(name: string, plug: Plug) {
    const { slots, plugs } = this;
    plugs.set(name, (plugs.get(name) || new Set()));
    const set = plugs.get(name)!;
    set.add(plug);
    return () => {
      set.delete(plug);
    }
  }
  
  wakeSlots(name: string) {
    for (const slot of (this.slots.get(name)! || emptyArray)) {
      scheduleReactUpdate(slot.wakeUp);
    }
  }
  
  static make(parent: ActionContext | null, id: string, levels: string | Iterable<string>) {
    if (typeof levels === "string")
      levels = [levels];
    return new ActionContext(parent, id, levels);
  }
}

interface Slot {
  id: string
  wakeUp: () => void
}

interface Plug {
  props: ActionPlugProps
}

const actionContext = React.createContext(new ActionContext(null, "global", "global"));
